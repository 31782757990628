<template>
  <PageWithLayout :isBodyBg="false">
    <div class="group_box">
      <BoxView title="Stepper" :grid="100">
        <Stepper
          :dataList="stepDataList"
          :currentStep="currentStep"
          @onChangeStep="id => onChangeStepById(id)"/>
        <Button
          btnSize="small"
          btnStyle="secondary_border"
          text="이전"
          :disabled="currentStep.index === 1"
          @onClickBtn="onClickPrevStep()"/>
        <Button
          btnSize="small"
          btnStyle="primary_border"
          text="다음"
          :disabled="currentStep.index === 6"
          @onClickBtn="onClickNextStep()"/>
      </BoxView>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';
import Stepper from '@lemontree-ai/lemontree-admin-common-front/components/common/stepper/Stepper';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';

export default {
  name:'Step',
  components:{
    PageWithLayout,
    BoxView,
    Stepper,
    Button,
  },
  data(){
    return{
      stepDataList: [
        { index:1, name:'첫번째 스탭', id:'step1', iconName:'logo' },
        { index:2, name:'두번째 스탭', id:'step2', iconName:'logo' },
        { index:3, name:'세번째 스탭', id:'step3', iconName:'logo' },
        { index:4, name:'네번째 스탭', id:'step4', iconName:'logo' },
        { index:5, name:'다섯번째 스탭', id:'step5', iconName:'logo' },
        { index:6, name:'여섯번째 스탭', id:'step6', iconName:'logo' },
      ],
      currentStep: { index:1, name:'첫번째 스탭', id:'step1', iconName:'logo' }
    }
  },
  methods:{
    onChangeStepById(changedId){
      this.currentStep = this.stepDataList.find(step => step.id == changedId)
    },
    onClickPrevStep(){
      const prevIndex = this.currentStep.index-1;
      this.currentStep = this.stepDataList.find(step => step.index == prevIndex)
    },
    onClickNextStep(){
      const nextIndex = this.currentStep.index+1;
      this.currentStep = this.stepDataList.find(step => step.index == nextIndex)
    }
  }
}
</script>

<style scoped>
</style>